:root {
  --app-height: 100vh;
}

Canvas {
  height: var(--app-height);
}

.loading-scene {
  height: var(--app-height);
}

.hidden {
  opacity: 0;
  transition: all 500ms linear 0s;
}